import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, Input, signal } from '@angular/core';

@Component({
  selector: 'app-stat-display',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './stat-display.component.html',
  styleUrl: './stat-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatDisplayComponent {

  value = input<string>();
  detail = input<string>();
  name = input<string>();

  detailShown = signal(false);

  @Input() textColor: string = '#FFFFFF';

  toggleDetail() {
    if(this.detail())
      this.detailShown.set(!this.detailShown());
  }
}
