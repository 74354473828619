<div class="rounded-sm border m-2 min-w-24">
    <div class="h-24 flex items-center justify-center" (click)="toggleDetail()">
        <span class="text-4xl font-bold" [ngStyle]="{
            color: textColor
        }">{{detailShown() ? detail() : value()}}</span>
    </div>
    <hr>
    <div class="text-center text-xs font-bold py-1">
        {{name()}}
    </div>
</div>